<script>
// import this.$axios.get from "this.$axios.get";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, minValue } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import { AlertDataSend } from "../components/alert";
import AlterarSenha from './AlterarSenha.vue';

const url = process.env.VUE_APP_API_URL;

export default {
  name: "PerfilEmpresa",
  components: { AlterarSenha },
  setup() {
    return { v$: useVuelidate() };
  },
  directives: { mask },

  data() {
    return {
      nome: null,
      senha: "",
      imagemTratada: "",
      mostrarSenha: false,
      modoEdicaoSenha: false,
      mostrarIconeNome: false,
      mostrarIconeQuantidade: false,
      quantidadeUruario: null,
      exibirQuantidadeUsuario: true,
      numeroBot: null,
      numeroWpp:null,
      mostrarModalAlterarSenha: false,
    };
  },
  props: {
    idEmpresa: String,
    nomePreenchido: Boolean,
    usuarioPreenchido: Boolean,
    empresasConectadasDados: Array,
  },

  validations() {
    return {
      nome: { required },
      senha: { required, minLengthRef: minLength(8) },
      quantidadeUruario: { required, minValue: minValue(1) },
      numeroWpp:{ required, minLengthRef: minLength(15) },
    };
  },

  async created() {
    if (this.idEmpresa) {
      await this.$axios
        .get(url + "/empresa", {
          params: { id: this.idEmpresa },
        })
        .then((response) => {
          this.nome = response.data[0].nome;
          this.senha = response.data[0].password;
          this.numeroWpp = response.data[0].contato_admin
          this.quantidadeUruario = response.data[0].qtd_usuario;
          this.numeroBot = response.data[0].numero_bot;
        });
    }
    document.addEventListener("keydown", this.handleEscKey);
  },
  beforeDestroy() {
    // document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener("keydown", this.handleEscKey);
  },

  methods: {

    handleEscKey(event) {
      if (event.key === "Escape") {
        this.$emit("fecharCadastroEmpresa");
      }
    },
    abrirModalAlterarSenha() {
      this.mostrarModalAlterarSenha = true;
    },
    fecharModalAlterarSenha() {
      this.$emit('fecharCadastroEmpresa');
      this.mostrarModalAlterarSenha = false;
    },
    ocultarQuantidadeUsuarios() {
      this.exibirQuantidadeUsuario = false;
    },

    esconderIcone(campo) {
      if (campo === "nome") {
        this.mostrarIconeNome = false;
      } else if (campo === "cnpj") {
        this.mostrarIconeCnpj = false;
      }
    },
  },
};
</script>
<template>
  <main class="conteudo">
    <section>
      <div class="caixaTitulo">
        <div class="caixaTitulo_titulo">
          <span> Perfil da empresa</span>
          <span @click="$emit('fecharCadastroEmpresa')" class="btn_sair">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
      <div class="formConteiner">
        <form class="item" id="elemento_form">
          <label class="titulo_form"> </label>
          <div class="caixa_input">
            <label for="nome">Login</label>
            <div class="input_icon">
              <input
                :class="[{ error: v$.nome.$error }]"
                type="text"
                id="nome"
                placeholder="Nome da empresa"
                autocomplete="off"
                class="input_padraoo"
                v-model="v$.nome.$model"
                :readonly="nomePreenchido"
                :style="{
                  cursor:
                    idEmpresa && usuarioPreenchido ? 'not-allowed' : 'auto',
                }"
              />
            </div>
            <p class="input_error" v-if="v$.nome.$error">Campo obrigatório</p>
          </div>
          <div class="caixa_input">
            <label for="nome">Número do administrador (WhatsApp)</label>
            <div class="input_icon">
              <input
                v-model="numeroWpp"
                :class="[{ error: v$.numeroWpp.$error }]"
                type="tel" id="numero" v-mask="'55(##)####-#####'"
                placeholder=" 🇧🇷 (DDD) 0000-0000"
                autocomplete="off"
                class="input_tel"
                :style="{
                    cursor:
                      idEmpresa && usuarioPreenchido ? 'not-allowed' : 'auto',
                  }"
              />
            </div>
            <p class="input_error" v-if="v$.numeroWpp.$error">Campo obrigatório</p>
          </div>
          <div class="caixa_input">
              <div class="usuario-style" v-if="exibirQuantidadeUsuario">
                <label for="usuarios">Usuários</label>
                <input
                  :class="[{ erro: v$.quantidadeUruario.$error }]"
                  type="number"
                  class="input_padraoo"
                  id="usuarios"
                  v-model="v$.quantidadeUruario.$model"
                  :readonly="usuarioPreenchido"
                  :style="{
                    cursor:
                      idEmpresa && usuarioPreenchido ? 'not-allowed' : 'auto',
                  }"
                />
                <p class="input_error" v-if="v$.quantidadeUruario.$error">
                  Informe um valor (mínimo: 1).
                </p>
              </div>
            </div>
            </form>
            </div>
            <label> 
                <div>
                    <button class="button-alterar" @click="abrirModalAlterarSenha">
                        Alterar Senha 
                        <p class="traco"></p>
                    </button>
                </div>
            </label>
    </section>
    <AlterarSenha 
      v-if="mostrarModalAlterarSenha" 
      @fecharCadastroEmpresa="fecharModalAlterarSenha"
      :idEmpresa="idEmpresa"
    />
  </main>
</template>
<style scoped>
.input_padraoo{
  width: 100%;
  background-color: #f0f0f0; 
  color: #b3b3b3; 
  
  height: 3vw;
  border: none;
  border-radius: 4px;
  padding: 0px 10px;
  font-size: 1rem;
}
.input_tel{
    width: 100%;
    background-color: #f0f0f0; 
  color: #b3b3b3; 
   
  height: 3vw;
  border: none;
  border-radius: 4px;
  padding: 0px 10px;
  font-size: 1rem;
}
.button-alterar{
    border: none;
    color:#b3b3b3;
    background-color: transparent;
    cursor: pointer;
    font-size: 1rem;
    padding: 15px 5px;

}
.traco {
  display: flex;
  width: 100%;
  border: 1px solid #b3b3b3;
}

.usuario-style {
  width: 100%;
}
.senha-usuario-style {
  float: left;
  display: flex;
  justify-content: space-between;
}
.caixaTitulo_titulo {
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  width: 100%;
}

.btn_sair {
  cursor: pointer;
}

.formConteiner {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}

.caixaTitulo {
  width: 100%;
}

section {
  border-radius: 10px;
  display: flex;
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  justify-content: space-around;
  flex-direction: column;
}

.conteudo {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  /* Cor de fundo semitransparente */
  font-family: Arial, Helvetica, sans-serif;
}

.conteudo .item {
  height: 100%;
}

.caixa_input {
  width: 100%;
}

.espaco_imagem {
  height: 60%;
  aspect-ratio: 1/1;
}

#elemento_form,
#elemento_imagem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

#senha {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.verSenha {
  display: flex;
  align-items: center;
  width: 87%;
}

.verSenha button {
  width: 15%;
  background: transparent;
  color: black;
  border: 1px solid #b3b3b3;
  height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
}

.state {
  cursor: pointer;
}

#input-img {
  display: none;
}

.input-imagen {
  text-align: center;
  border: 1px solid #b3b3b3;
  padding: 15px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

#textoobservacao {
  font-size: 13px;
  color: #b3b3b3;
}

#geraSenha {
  background-color: white;
  height: 30px;
  border-radius: 5px;
  font-weight: 100;
  cursor: pointer;
}

.botao_padrao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 30px;
  background-color: #2bb542;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 2px;
}

.caixa_botao {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
