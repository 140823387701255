function AlertDataSend(mensagem, tipo) {
  if (tipo == 'alert') {
    const divAviso = document.createElement("div");
    divAviso.classList.add("alert_dataSend");
    divAviso.textContent = mensagem;

    document.body.appendChild(divAviso);

    setTimeout(() => {
      divAviso.remove();
    }, 3000); // Remove o aviso após 3 segundos

    return divAviso;

  } else {
    const divAviso = document.createElement("div");
    divAviso.classList.add("alert_dataSend_erro");
    divAviso.textContent = mensagem;

    document.body.appendChild(divAviso);

    setTimeout(() => {
      divAviso.remove();
    }, 3000); // Remove o aviso após 3 segundos

    return divAviso;
  }

}

export { AlertDataSend }