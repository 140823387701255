<template >
  <main class="template">
    <Sidebar v-if="!estaLogado"></Sidebar>
    <router-view></router-view>
  </main>
</template>

<script>
import Sidebar from './views/Sidebar.vue';
import router from './router/routes';

export default {
  name: "App",
  components: {
    Sidebar
  },
  computed: {
    estaLogado() {
      const ValidRouter = this.$route.name !== 'NotFound'; 
      
      const logged =  this.$route.path !== '/';
      if (logged && ValidRouter){
        return false
      }
      else{
        return true
      }
},  
  },
  methods: {

    autorizar() {
      this.$store.commit('autorizar')
    },
    desautorizar() {
      this.$store.commit('desautorizar')
    },
  },
};
</script>


<style>

.alert_dataSend{
height: fit-content;
width: fit-content;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  background: white;
  padding: 20px 30px 20px 30px;
  font-size: 1rem;
  color: green;
  font-weight: bolder;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 2px  grey;
  z-index: 9999;

  animation: apareceDesaparece 3s linear infinite;
}
.alert_dataSend_erro{
height: fit-content;
width: fit-content;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  background: white;
  padding: 20px 30px 20px 30px;
  font-size: 1rem;
  color: red;
  font-weight: bolder;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 2px  grey;
  z-index: 9999;

  animation: apareceDesaparece 3s linear infinite;
}

@keyframes apareceDesaparece{
 0%,100%{
  opacity: 0;
 }

  20%,80%{
  opacity: 100%;
  }

}

</style>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


body{
  background-color: #eee;
}
.alertaConexao {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  background-color: #2bb542;
  color: white;
}
</style>


<style>

*{
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
}


/* Estilos globais */
::-webkit-scrollbar {
  overflow: hidden;
  width: 3px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
  overflow: hidden;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid rgb(126, 126, 126);
  /* creates padding around scroll thumb */
  overflow: hidden;
}

:hover::-webkit-scrollbar {

  overflow: hidden;
}
</style>
