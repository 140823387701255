<script>
import dias from "../components/cadastroUsuario/popUp.vue"
export default {
    name: "cadastronew",

    components: {
        dias
    },

    data() {
        return {
            enabled: false
        }
    }
}
</script>

<template>
    <main class="base">
        <header>
            <div class="caixaTitulo">
                <div class="caixaTitulo_titulo">
                    Teste
                </div>
                <div>
                    <button class="caixaTitulo_botao">Adicionar</button>
                </div>
            </div>
        </header>
        <section class="conteudo" >
            <form class="item" id="elemento_form">
                <label class="titulo_form">Sua empresa</label>
                <div class="caixa_input">
                <label>Email</label>
                <input class="input_padrao" type="text">
                <p id="error"> Campo obrigatorio</p>
                <p class="input_error"> Campo obrigatorio</p>
                </div>
    
                <div class="caixa_input">
                <label>senha</label>
                <input class="input_padrao" type="text">
                <p id="error"> Campo obrigatorio</p>
                <p class="input_error">Campo obrigatorio</p>
                </div>
    
                <div class="caixa_input">
                <label>input</label>
                <input class="input_padrao" type="text">
                <p id="error"> Campo obrigatorio</p>
                <p class="input_error"> Campo obrigatorio</p>
                </div>
                <div class="caixa_botao">
                    <button class="botao_padrao">enviar</button>
                </div>
            </form>
            <div class="item" id="elemento_imagem">
                <label class="titulo_form">Sua empresa</label>
                <div class="espaco_imagem">
                    <img src="" alt="">
                </div>
                <div class="caixa_botao">
                    <button class="botao_padrao">enviar</button>
                    <button class="botao_padrao">Enviar</button>
                </div>
            </div>
        </section>
        <section class="conteudo" >
            <div class="pop-up">

                <dias></dias>
            </div>
        </section>
    </main>
    </template>
    

<style>
.base {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 200px;
    margin-top: 20px;
    
}

.caixaTitulo,
.conteudo {
    width: 80vw;
    
}

.caixaTitulo {
    display: flex;
    justify-content: space-between;
    padding: 0;
    height: 60px;
    align-items: center;
    align-content: center;
    
}


.conteudo {
    height: 80vh;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
    background-color: white;
    box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);    
}

.caixaTitulo_titulo {
    font-size: 2rem;
    font-weight: 700;
}

.caixaTitulo_botao {
    padding: 10px;
    width: 230px;
    background-color: #2bb542;
    border: none;
    color: white;
    cursor: pointer;
}

.btn_edit {
    color: black;
    backface-visibility: hidden;
    border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.btn_rm {
    color: red;
    border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.conteudo {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.conteudo .item {
    width: 23vw;
    height: 70vh;

}

#elemento_form,
#elemento_imagem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}


.input_padrao,
.botao_padrao,
.state {
    height: 40px;
    width: 100%;
    background-color: white;
    border-radius: 5px;
}

.input_padrao {
    padding-left: 10px;
    border: 1px solid #b3b3b3;
    font-size: 1rem;
    color: #b3b3b3;
}

.botao_padrao {
    font-size: 1rem;
    border: none;
    font-weight: bolder;
    /* box-shadow: 1px 1px 2px 2px rgb(221, 221, 221); */
    cursor: pointer;
}

.validado {
    background-color: #2BB542;
    color: white;
}


.titulo_form {
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}

.espaco_imagem {
    width: 100%;

    border-radius: 10px;
    aspect-ratio: 1/1;

    box-shadow: 1px 1px 2px 2px rgb(217, 217, 217)
}

.state {
    text-align: center;
    padding: 12px;
    border: 1px solid #b3b3b3;
    font-weight: bold;
    border-radius: 5px;
    height: 42px;
}

.input_error {
    color: rgb(255, 108, 108);
    font-size: 0.8rem;
}
</style>


<style scoped>
.error {
    border: 1px solid rgb(255, 108, 108)
}
</style>