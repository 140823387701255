<template>
<div class="message-conteiner">
    <p>{{ msg }}</p>
</div>
    
</template>

<script>
export default {
    name: "Message",
    props:{
        msg : String
    }
    
}
</script>

<style>
.message-conteiner{
    display: flex;
    color: white;
    background-color: rgba(43, 181, 66, 0.7);
    border: 2px solid rgba(43, 181, 66, 0.7);
    border-radius: 5px;
    padding: 10px;
    width: 300px;
    height: 50px;
    float: right;
    justify-content: center;
    align-items: center;
    transition: 0.9s;
}

</style>

