<template>
  <main>
    <div class="container-switch">
      <!--refatorar o componente -->
      <input type="checkbox" :id="'toggle-' + IdUnico" class="check" v-model="isChecked" />
      <label :for="'toggle-' + IdUnico" class="toggle-switch" @click=tomarDecisao(IdUnico)>
        <!-- passar vif aqui -->
        <div class="ativo">ON</div>
        <div class="inativo">OFF</div>
      </label>
    </div>
  </main>
</template>

<script>
//import this.$axios.get from 'this.$axios.get';
import { deleteDados } from './empresasConectadasDados';
const url = process.env.VUE_APP_API_URL_SOCKET
export default {
  name: "ToggleSwitchEmpresa",
  data() {
    return {
      // Estado atual do Toggle Switch
      isChecked: true

    };
  },

  props: {
    ordenado:Boolean,
    IdUnico: {
      required: true,
    },
    sessao: {
      required: true,
    },
  },
  watch: {
    ordenado() {
      this.isChecked = !this.isChecked
    }
  },
  //passar a resposabilidade para o componente pai
  async created() {
    const data = { session: this.sessao }

    await this.$axios.post(url + '/session', data).then((response) => {
      this.isChecked = false
    }).catch(() => {
      this.isChecked = true
    })
  },
  methods: {
    encerrarConexao() {
      deleteDados(this.sessao)
    },

    async tomarDecisao(id) {
      if (this.isChecked === false) {
        this.encerrarConexao
        await this.$axios.delete(url+'/session/logout', {
          data: { session: this.sessao }
        }).then(() => {
          this.isChecked = !this.isChecked
        })
      }
      else {
        try {
          this.reconectarConexao
        } catch (error) {
          this.isChecked = !this.isChecked
        }

      }
    },
  }
}
</script>

<style scoped>
main {
  width: 100px;
}

.toggle-switch {
  width: 100px;
  height: 20px;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #b3b3b3;
  background: white;
  transition: background-color 0.3s;
  cursor: pointer;
  font-size: 0.8rem;
}

.check {
  display: none;
}

.ball {
  position: relative;
  cursor: pointer;
  width: 120px;
  height: 20px;
  border-radius: 15px;
  background-color: transparent;
  transition: transform 0.3s;
  font-size: 1rem;
  font-weight: lighter;
}
.ativo {
  background: #2bb542;
  color: white;
}

.ativo,
.inativo {
  width: 50%;
  border-radius: 15px;

  height: 100%;
  padding-top: 2px;

  -webkit-touch-callout: none;
  /* iPhone OS, Safari */
  -webkit-user-select: none;
  /* Chrome, Safari 3 */
  -khtml-user-select: none;
  /* Safari 2 */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
}
.container-switch input:checked~label .inativo {
  background: red;
  color: white;
}
.container-switch input:checked~label .ativo {
  background: #2bb542;
  color: white;
}
.container-switch input:checked~label .ativo {
  background: transparent;
  color: black;
}
</style>
