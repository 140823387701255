<template>
  <div class="all-stylee">
    <div class="wrapper">
      <div class="select-btn" @click="openSelected">
        <span>{{ selected }}</span>
        <i class="fas fa-angle-down" id="icon-style" :class="{ rotated: showSelected }"></i>
      </div>
      <div class="content" v-if="showSelected">
        <ul class="options">
          <li class="style-removeExpediente" v-if="expedienteRemove" @click="updateSearch(null)">
            Remover expediente
          </li>
          <li v-for="(option, index) in options" :key="index" @click="updateSearch(option)">
            {{ option.nome }}
          </li>
        </ul>
          <button v-if="addExpediente" class="button-expediente" @click="adcExpediente()">
            <i class="fa-solid fa-plus"></i>Adicionar Expediente
          </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectDatasend",
  props: {
    options: Array, 
    selected: String,
    spinner: Boolean,
    showLoadButton: Boolean,
    loading: Boolean,
    addExpediente: Boolean,
    expedienteRemove:Boolean,
  },
  data() {
    return {
      showSelected: false,
      pesquisa: "",
      expedientes: [],
    };
  },
  created() {
    window.addEventListener("click", this.closeOnOutsideClick)
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeOnOutsideClick);
  },
  methods: {
    openSelected() {
      this.showSelected = !this.showSelected;
    },
    updateSearch(option) {
      if(option === null) {
        this.$emit("input", { nome: "Remover expediente" });
      } else {
        this.$emit("input", option);
      }
      this.$emit("update")
      this.showSelected = false;
      // this.$emit("input", option);
      // this.$emit("update")
      // this.showSelected = false;
      // this.$emit("option-selected", option);
    },
    adcExpediente(){
      this.$router.push({name: 'telaExpediente', query: {popup: true}})
    } , 
    closeOnOutsideClick(event) {
      const isClickInsideComponent = this.$el.contains(event.target);
      if (!isClickInsideComponent) {
        this.showSelected = false;
      }
    }
  }
}
</script>
<style scoped>
.wrapper {
  width: 17.8vw;
  position: relative;
  overflow: hidden;
}
.all-stylee{
  width: 12vw;
  border: none;
  background-color: transparent;
  max-width: 100%;
}
#icon-style{
  font-size: 0.8rem;
 
}
.select-btn,
.options li {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.style-removeExpediente{
  color: red;
  font-size: 1.2rem;
}
.select-btn {
  position: relative;
  width: 17rem;
  height: 5.5vh;
  font-size: 17px;
  display: flex;
  padding: 0 10px;
  border: 1px solid #929090;
  border-radius:10px;
  cursor: pointer;
  color: black;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  max-width: 100%;
}
.button-expediente{
  width: 15.9vw;
  height: 5vh;
  color: white;
  background-color: #9290909d;
  border: none;
  cursor: pointer;
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
}

.select-btn span {
  max-width: 11vw;
  overflow: hidden;
}

.fa-angle-down.rotated {
  transform: rotate(-180deg);
  transition: transform 0.5s linear;
}

.content {
  margin-top: 4px;
  padding: 8px;
  width: 18.4%;
  background: #fff;
  border: 1px solid #929090;
  color: black;
  border-radius: 7px;
  position: fixed;
}

.search input {
  height: 30px;
  width: 100%;
  outline: none;
  font-size: 16px;
  border-radius: 5px;
  padding: 0 15px 0;
  border: 1px solid #929090;
}

.content .options {
  margin-top: 10px;
  max-height: 20vh;
  overflow-y: auto;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.options::-webkit-scrollbar {
  width: 7px;
}

.options::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 25px;
}

.options::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 25px;
  border: none;
}

.options li {
  height: 50px;
  padding: 0 35px;
  font-size: 16px;
  border-radius: 5px;
}

.options li:hover {
  background: #eeeded;
}
#button-add:hover .DicaTexto{
    visibility: visible;
  }
  #button-load{
    position: relative; 
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: green;
    margin-left: 7vw;
}
#button-load:hover .DicaTexto{
    visibility: visible;
  }

/* .DicaTexto{
  position: absolute;
  visibility: hidden; font-size: 13px;
   width: 100px; 
  background: rgba(37, 37, 37, 0.695);
  color:#fff; 
  text-align: center;
  padding: 5px  0; 
  border-radius: 4px; 
  bottom: 100%; 
  left: 50%; 
  margin-left: -50px; 
  margin-bottom: 5px;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}
.DicaTexto:after{
  content: " "; 
  position: absolute; 
  top: 100%; 
  left: 50%; 
  margin-left: -5px; 
  border-width: 5px; 
  border-style: solid; 
  border-color: rgba(37, 37, 37, 0.695) transparent transparent transparent;
  } */
  /* .rotated {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
} */

.rotating {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
