<template>
    <nav>
      <ul>
        <li>{{ filtroSelecionado }}
          <div class="sub-menu-1">
            <ul>
              <li v-for="item in data" :key="item.id" @click="() => atualizarFiltro(item.title, item.id)"> {{item.title}} </li>
            </ul>
          </div>
        </li>
      </ul>
      <input type="text" for="conteudo" placeholder="Conteúdo" id="in">
      <input type="text" for="campo" placeholder="Campo" id="in">
    </nav>
  </template>
  
  <script>
  //import this.$axios.get from 'this.$axios.get'
  
  export default {
    name: 'Dropdown',
    data() {
      return {
        filtroSelecionado: 'Selecione o filtro',
        data : null,
        campo: null,
        conteudo: null
      };
    },
    methods: {
      atualizarFiltro(valor, id) {
        console.log(this.campo)
        console.log(this.conteudo)
        console.log(id)
        console.log(valor)
        this.filtroSelecionado = valor;
      }
      
    },
    mounted() {
      this.$axios.get('http://localhost:3000/qlik').then((response) =>{
        console.log(response.data)
        this.data = response.data
      }).catch((err) => {
        console.log(err)
      })
    }
  };
  </script>
  
  <style>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  nav ul{
    border: 1px solid;
    width: 160px;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    display: inline-flex;
    list-style: none;
  }
  nav ul li{
    width: 120px;
    cursor: pointer;
    
  }
  .sub-menu-1{
    display: none;
  }
  nav ul li:hover .sub-menu-1{
    display: block;
    position: absolute;
    background-color: white;
  }
  nav ul li:hover .sub-menu-1 ul{
    display: block;
  
  }
  nav ul li:hover .sub-menu-1 ul li {
    width: 150px;
    text-align: center;
    border-radius: 10px;
    transition: 0.7s;
  
  }
  nav ul li:hover .sub-menu-1 ul li:hover{
    background-color: aquamarine;
  
  }
  .sub-menu-2{
    display: none;
  }
  .hover-sub:hover .sub-menu-2{
    display: block;
    position: absolute;
    margin-top: -40px;
    margin-left: 140px;
    background-color: white;
  }
  #in{
    width: 100px;
    margin-right: 10px;
    border-radius: 10px;
    float: right;
    margin-top: 10px;
  }
  </style>