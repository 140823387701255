<script>
import { fetchDados } from './empresasConectadasDados';
export default{
    name:"empresasConectadas",

    data(){
        return{
          empresasConectadasDados:  null
    }
  },
  async mounted() {
    this.empresasConectadasDados = await fetchDados();
    
},

}
</script>


<template>
<section class="item" id="elemento_form">
  <label class="titulo_form">Empresas conectadas</label>
     <div class="espaco_imagem">
       <ul class="header">
          <li class="init"></li>
          <li>NOME</li>
          <li>ID</li>
          <li>Status</li>
          <li class="init" id="" style="text-align: center">
          </li>
          <li class="init" id="" style="text-align: center">
          </li>
          <li class="init"></li>
          </ul>
        <ul class="mutavel" v-for="dados in empresasConectadasDados">
          <li class="init" style="border: none"></li>
          <li style="border-left: none">{{ dados.nome }}</li>
          <li>{{ dados.id_empresa }}</li>
          <li>conexão</li>
          <li class="init" style="text-align: center">
            <i class="fa fa-pencil"></i>
          </li>
          <li class="init" style="text-align: center; border-left:0px;">
          </li>
        </ul>
      </div>
      <div class="caixa_botao">
          <button class="botao_padrao"></button>
      </div>
</section>
</template>

<style scoped>

section{
  width: 100%;
}


ul{
    background-color:transparent;
}

.espaco_imagem {
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: none;
}

.botao_padrao{
  box-shadow: none;
}
  

li{
  overflow-x: hidden
}

ul {
    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    list-style-type: none;
    width: 100%;
    margin-top: 10px;
    font-size: 15px;
  }
  
  ul li {
    width: 40%;
  }
  
  li.init {
    border: none;
    width: 10%;
  }
  
  ul.mutavel {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 5px;
    font-size: 15px;
    text-align: left;
    border-bottom: 1px solid #b3b3b3;
  }
ul.mutavel li {
    border-left: 1px solid #b3b3b3;
    min-height: 20px;
    text-align: left;
    padding-top: 2px;
    padding-left: 5px;
  }
  
ul.header {
  color: green;
}
  
li{
    font-size: small;
    width: 100%;
}
  
.mutavel div {
  border-radius: 5px;
  height: 100%;
  border: 1px solid #b3b3b3;
}

</style>