import { createRouter, createWebHistory } from 'vue-router'
import { checkToken } from '../stores/auth.js'
import { routes } from './routes'

const origin = process.env.VUE_APP_ORIGIN;
const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.name) {
    to.query.origin = origin;
  }
  if (to.meta?.public) {
    next()
  } else if (to.meta?.auth) {

    const isAuthenticated = await checkToken()
    if (isAuthenticated) {
      if (to.meta?.admin) {
        const devAut = localStorage.getItem('vuex')
        if (devAut) {
          next()
        } else {
          next({ name: 'usuario' })
        }
      } else {
        next()
      }
    } else {
      next({ name: 'home' })
    }
  } else {
    next({ name: 'home' })
  }
})

export default router
