  
  <template>
    <main class="base">
      <header class="caixaTitulo">
        <div class="caixaTitulo_titulo">Configuração</div>
        <div></div>
      </header>
      <section class="conteudo">
        <div class="all-content">
          <label class="picture" for="picture__input" tabIndex="0">
            <span class="picture__image">
              <span v-if="!imageSrc">{{ pictureImageTxt }}</span>
              <img v-if="imageSrc" :src="imageSrc" class="picture__img" />
            </span>
            <input
              type="file"
              name="picture__input"
              id="picture__input"
              @change="handleFileChange"
            />
          </label>
          <button class="button" @click="triggerFileInput">
            Upload Image
          </button>
        </div>
      </section>
    </main>
  </template>
  
  <script>
  export default {
    data() {
      return {
        imageUrl: require("/DataSendImagens/Group177.png"),
        pictureImageTxt: "Choose an image",
        imageSrc: require("/DataSendImagens/Group177.png"),
      };
    },
    methods: {
      triggerFileInput() {
        document.getElementById('picture__input').click();
      },
      handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            console.log(e.target.result);
            this.imageSrc = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.imageSrc = null;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .base {
    display: flex;
  }
  .conteudo {
    display: flex;
  }
  .all-content {
    width: 90%;
    height: 90%;
  }
  
  .img-login {
    display: flex;
    height: 30vh;
    width: 30vw;
    background-size: cover;
  }
  
  .contorno {
    box-shadow: 1px 1px 20px #b3b3b3;
  }
  .button {
    height: 2.5em;
    border: 1px solid #b3b3b3;
    background-color: #2BB542;
    cursor: pointer;
    width: 26vw;
    padding: 5px 30px;
    border-radius: 3px;
    color: white;
    margin-top: 15px;
  }
  
  input {
    color: #2bb542;
    cursor: pointer;
  }
  
  #picture__input {
    display: none;
  }
  
  .picture {
    width: 400px;
    aspect-ratio: 16/9;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
    border: 2px dashed currentcolor;
    cursor: pointer;
    font-family: sans-serif;
    transition: color 300ms ease-in-out, background 300ms ease-in-out;
    outline: none;
    overflow: hidden;
  }
  
  .picture:hover {
    color: #777;
    background: #ccc;
  }
  
  .picture:active {
    border-color: turquoise;
    color: turquoise;
    background: #eee;
  }
  
  .picture:focus {
    color: #777;
    background: #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .picture__img {
    max-width: 100%;
  }
  </style>
  