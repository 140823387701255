<script>
export default {
  name: 'NotFound',

  methods: {
    backToSite() {
    const token = localStorage.getItem('token')
    if(token){
        this.$router.push('/usuario')
    }   
    else{
        this.$router.push('/')
    }
    },
  },
}
</script>

<template>
  <main>
    <div class="triangle"></div>
    <section>
      <div class="image-container">
        <img src="../../DataSendImagens/Logo.png" alt="" />
      </div>

      <span><strong>Parece que algo deu errado...</strong></span>
      <span class="voltar" @click="backToSite"> Voltar para o DataSend ></span>
    </section>
    <section>
      <span class="error404">404</span>
    </section>
  </main>
</template>

<style scoped>
.error404 {
  font-size: 20vw;
  font-weight: 600;
  font-family: sans-serif;
  background-image: linear-gradient(#7dd38b, #2bb542);
  background-clip: text;
  color: transparent;
}

main {
  display: flex;
  width: 90;
  height: 90vh;
  background: white;
}

section {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  z-index: 2;
}

span {
  width: 80%;
  font-size: 2rem;
}

.triangle {
  width: 0;
  height: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  border-left: 20vw solid transparent;
  border-bottom: 20vw solid #2bb542;
}

.voltar {
  font-size: 1rem;
  color: #2bb542;
  cursor: pointer;
}

.voltar:hover {
  text-decoration: underline;
}

img {
  width: 20%;
  aspect-ratio: 1/1;
}

section{
   user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.image-container {
  width: 80%;
  display: flex;
}
</style>
