<script>
// import this.$axios.get from "this.$axios.get";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, minValue } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import { AlertDataSend } from "../components/alert";
import { limpaMascara } from "../components/cadastroUsuario/util.js";

const url = process.env.VUE_APP_API_URL;

export default {
  name: "cadastroEmpresa",
  setup() {
    return { v$: useVuelidate() };
  },
  directives: { mask },

  data() {
    return {
      nome: null,
      senha: "",
      imagemTratada: "",
      mostrarSenha: false,
      modoEdicaoSenha: false,
      mostrarIconeNome: false,
      mostrarIconeQuantidade: false,
      quantidadeUruario: null,
      exibirQuantidadeUsuario: true,
      numeroBot: null,
      numeroWpp:null,
    };
  },
  props: {
    idEmpresa: String,
    nomePreenchido: Boolean,
    usuarioPreenchido: Boolean,
    empresasConectadasDados: Array,
  },

  validations() {
    return {
      nome: { required },
      senha: { required, minLengthRef: minLength(8) },
      quantidadeUruario: { required, minValue: minValue(1) },
      numeroWpp:{ required, minLengthRef: minLength(15) },
    };
  },

  async created() {
    if (this.idEmpresa) {
      await this.$axios
        .get(url + "/empresa", {
          params: { id: this.idEmpresa },
        })
        .then((response) => {
          this.nome = response.data[0].nome;
          this.senha = response.data[0].password;
          this.quantidadeUruario = response.data[0].qtd_usuario;
          this.numeroBot = response.data[0].numero_bot;
        });
    }
    document.addEventListener("keydown", this.handleEscKey);
  },
  beforeDestroy() {
    // document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener("keydown", this.handleEscKey);
  },

  methods: {
    async gerarSenha() {
      const senha = await this.$axios.get(url + "/gerarsenha");
      this.senha = senha.data;
    },

    mudarEstadoSenha() {
      this.mostrarSenha = !this.mostrarSenha;
    },
    handleEscKey(event) {
      if (event.key === "Escape") {
        this.$emit("fecharCadastroEmpresa");
      }
    },
    definirModoEdicaoSenha() {
      this.modoEdicaoSenha = true;
    },
    confirma_envio() {
      this.v$.$validate();

      if(!this.numeroWpp){
        AlertDataSend("O número do administrador é obrigatório.");
        return;
      }

      const empresa = this.empresasConectadasDados?.find(
        (emp) => emp.id_empresa === this.idEmpresa
      );
      if (!empresa) {
        if (this.v$.$error || this.v$.quantidadeUruario.$error) {
          AlertDataSend("Preencha os campos corretamente");
        } else {
          this.enviarEmpresa();
        }
        return;
      }

      if (empresa.qtd_usuarios_cadastrados > this.quantidadeUruario) {
        AlertDataSend("Não foi possível atualizar");
        return;
      }
      if (this.v$.$error || this.v$.quantidadeUruario.$error) {
        AlertDataSend("Preencha os campos corretamente");
      } else {
        this.enviarEmpresa();
      }
    },
    ocultarQuantidadeUsuarios() {
      this.exibirQuantidadeUsuario = false;
    },

    esconderIcone(campo) {
      if (campo === "nome") {
        this.mostrarIconeNome = false;
      } else if (campo === "cnpj") {
        this.mostrarIconeCnpj = false;
      }
    },
    async enviarEmpresa() {
      try {
        const dados = {
          idEmpresa: this.idEmpresa,
          nome: this.nome,
          senha: this.senha,
          quantidadeUruario: this.quantidadeUruario,
          contato_admin: limpaMascara(this.numeroWpp)
        };
        
        if (this.idEmpresa) {
          if (this.nome) {
            this.$axios
              .put(url + "/empresa", dados)
              .then(async (response) => {

              })
              .catch((error) => {
                AlertDataSend("Não foi possível adicionar empresa.");
              });
          }
        } else {
          if (this.nome) {
            this.$axios
              .post(url + "/empresa", dados)
              .then(async (response) => {
                //this.$emit('fecharCadastroEmpresa')
                location.reload();
              })
              .catch((error) => {
                AlertDataSend("Não foi possível adicionar empresa.");
              });
          }
        }
      } catch (erro) {
        AlertDataSend("Erro ao cadastrar empresa.");
        console.error("Erro ao enviar empresa:", erro);
      }
    },
  },
};
</script>
<template>
  <main class="conteudo">
    <section>
      <div class="caixaTitulo">
        <div class="caixaTitulo_titulo">
          <span>Cadastro de empresa</span>
          <span @click="$emit('fecharCadastroEmpresa')" class="btn_sair">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
      <div class="formConteiner">
        <form class="item" id="elemento_form">
          <label class="titulo_form"> </label>
          <div class="caixa_input">
            <label for="nome">Login</label>
            <div class="input_icon">
              <input
                :class="[{ error: v$.nome.$error }]"
                type="text"
                id="nome"
                placeholder="Digite o login"
                autocomplete="off"
                class="input_padrao"
                v-model="v$.nome.$model"
                :readonly="nomePreenchido"
                :style="{
                  cursor:
                    idEmpresa && usuarioPreenchido ? 'not-allowed' : 'auto',
                }"
              />
            </div>
            <p class="input_error" v-if="v$.nome.$error">Campo obrigatório</p>
          </div>
          <div class="caixa_input">
            <label for="nome">Número do administrador (WhatsApp)</label>
            <div class="input_icon">
              <input
                v-model="numeroWpp"
                :class="[{ error: v$.numeroWpp.$error }]"
                type="tel" id="numero" v-mask="'55(##)####-#####'"
                placeholder=" 🇧🇷 (DDD) 0000-0000"
                autocomplete="off"
                class="input_padrao"
              />
            </div>
            <p class="input_error" v-if="v$.numeroWpp.$error">Campo obrigatório</p>
          </div>
          <div class="caixa_input">
            <div class="senha-usuario-style">
              <div class="senha-style">
                <label for="senha">Senha</label>
                <div class="verSenha">
                  <input
                    :class="[{ error: v$.senha.$error }]"
                    :type="mostrarSenha ? 'text' : 'password'"
                    class="input_padrao"
                    id="senha"
                    placeholder="********"
                    autocomplete="off"
                    v-model="v$.senha.$model"
                  />
                  <button @click="mudarEstadoSenha" type="button">
                    <i :class="mostrarSenha ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
                  </button>
                </div>
                <p class="input_error" v-if="v$.senha.$error">
                  mínimo 8 caracteres
                </p>
              </div>
              <div class="usuario-style" v-if="exibirQuantidadeUsuario">
                <label for="usuarios">Usuários</label>
                <input
                  :class="[{ erro: v$.quantidadeUruario.$error }]"
                  type="number"
                  class="input_padrao"
                  id="usuarios"
                  v-model="v$.quantidadeUruario.$model"
                  :readonly="usuarioPreenchido"
                  :style="{
                    cursor:
                      idEmpresa && usuarioPreenchido ? 'not-allowed' : 'auto',
                  }"
                />
                <p class="input_error" v-if="v$.quantidadeUruario.$error">
                  Informe um valor (mínimo: 1).
                </p>
              </div>
            </div>
          </div>
          <div class="caixa_botao">
            <button
              id="geraSenha"
              class="botao_padrao"
              @click.prevent="gerarSenha"
              style="color: black"
            >
              Gerar Senha
            </button>
            <div
              :class="[{ validado: !v$.$invalid }]"
              class="botao_padrao"
              type="submit"
              value="Salvar"
              @click="confirma_envio"
            >
              <p>Enviar</p>
            </div>
          </div>
        </form>
        <form class="item" id="elemento_imagem" v-if="false">
          <label class="titulo_form"> Imagem </label>
          <label class="espaco_imagem"> </label>
          <label class="state" for="input-img">
            <span class="picture-img">
              Enviar imagem
              <i class="fa-solid fa-arrow-up-right-from-square"></i>
            </span>
          </label>
          <input
            type="file"
            accept="image/jpeg, image/png, image/svg+xml"
            id="input-img"
          />
        </form>
      </div>
    </section>
  </main>
</template>
<style scoped>
.input_padrao {
  color: black;
}
.usuario-style {
  width: 60%;
}
.senha-usuario-style {
  float: left;
  display: flex;
  justify-content: space-between;
}
.caixaTitulo_titulo {
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  width: 100%;
}

.btn_sair {
  cursor: pointer;
}

.formConteiner {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}

.caixaTitulo {
  width: 100%;
}

section {
  border-radius: 10px;
  display: flex;
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  justify-content: space-around;
  flex-direction: column;
}

.conteudo {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Cor de fundo semitransparente */
  font-family: Arial, Helvetica, sans-serif;
}

.conteudo .item {
  height: 100%;
}

.caixa_input {
  width: 100%;
}

.espaco_imagem {
  height: 60%;
  aspect-ratio: 1/1;
}

#elemento_form,
#elemento_imagem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

#senha {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.verSenha {
  display: flex;
  align-items: center;
  width: 87%;
}

.verSenha button {
  width: 15%;
  background: transparent;
  color: black;
  border: 1px solid #b3b3b3;
  height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
}

.state {
  cursor: pointer;
}

#input-img {
  display: none;
}

.input-imagen {
  text-align: center;
  border: 1px solid #b3b3b3;
  padding: 15px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

#textoobservacao {
  font-size: 13px;
  color: #b3b3b3;
}

#geraSenha {
  background-color: white;
  height: 30px;
  border-radius: 5px;
  font-weight: 100;
  cursor: pointer;
}

.botao_padrao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 30px;
  background-color: #2bb542;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 2px;
}

.caixa_botao {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
