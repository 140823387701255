<script>
import popupAbout from "./popupAbout.vue"
import popupAboutDS from "./popupAboutDS.vue"
import popupAboutFun from "./popupAboutfun.vue"
import popupAboutUs from "./popupAboutUs.vue"
import popupSugestion from "./sugestion.vue"
export default {
  name: 'About',
  
  data() {
    return {
      popupStatus :false,
      idPopup: null
    }
  },

  components:{
    popupAbout, popupAboutDS,popupAboutFun, popupAboutUs, popupSugestion
  },

  methods: {
    showPopup(id){
      this.popupStatus = id
    },
    
    HandleHidePopup(){
      this.popupStatus = false
    },

  },
}


</script>

<template>
  <popupAbout v-if="popupStatus === 0"  @HandleHidePopup="HandleHidePopup" idPopup="idPopup" ></popupAbout>
  <popupAboutDS v-if="popupStatus === 1 "  @HandleHidePopup="HandleHidePopup" idPopup="idPopup" ></popupAboutDS>
  <popupAboutFun v-if="popupStatus === 2 "  @HandleHidePopup="HandleHidePopup" idPopup="idPopup"></popupAboutFun>
  <popupAboutUs v-if="popupStatus === 4 "  @HandleHidePopup="HandleHidePopup" idPopup="idPopup"></popupAboutUs>
  <popupSugestion v-if="popupStatus === 5 "  @HandleHidePopup="HandleHidePopup" idPopup="idPopup"></popupSugestion>
  <main class="base">
    <header>
      <div class="caixaTitulo">
        <div class="caixaTitulo_titulo">Sobre nós</div>
        <div>
        <button class="caixaTitulo_botao" @click='showPopup(5)' >Enviar sugestão  <i class="fa-solid fa-envelope"></i></button>
    </div>
      </div>

    </header>
    <section class="conteudo">
      <aside class="about">
        <h1>Perguntas Frequentes</h1>
        <div class="list_about">
          <li @click='showPopup(4)'>
            <span>Quem é a Data Priority? </span>
            <i class="fa-regular fa-note-sticky"></i>
          </li>
          <li @click='showPopup(1)'>
            <span>O que é o DataSend?</span
            ><i class="fa-regular fa-note-sticky"></i>
          </li>
          <li @click='showPopup(0)'>
            <span>Como o DataSend funciona?</span
            ><i class="fa-regular fa-note-sticky"></i>
          </li>
          <li @click='showPopup(2)'>
            <span>Funcinalidades do DataSend</span
            ><i class="fa-regular fa-note-sticky"></i>
          </li>
        </div>
      </aside>
      <div class="line"></div>
      <aside class="releases">
        <h1>Novidades</h1>
        <div class="list_about">
            <li v-for="item in 20" @click='showPopup'>
            <span>Release note V1.0</span
            ><i class="fa-solid fa-arrow-up-right-from-square"></i>
          </li>
        </div>
      </aside>
    </section>
  </main>
</template>

<style scoped>


.about,
.releases {
  padding-left: 5%;
  padding-top: 5%;
  width: 49%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.line {
  width: 2px;
  height: 80%;
  background: #2bb542;
  border-radius: 5px;
}

.list_about {
  width: 95%;
  list-style: none;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 85%;
  overflow-y: scroll;
}

li{
    width: 95%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

li:hover{
    border-bottom: 1px solid grey;
}

h1 {
  font-size: 2rem;
  color: #2bb542;
}
</style>
