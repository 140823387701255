<script>

</script>

<template>
  <div class="corpo">
    <div>
      <button class="btn-voltar" @click="mostrarIntrucoes">
        <i class="fa-solid fa-chevron-left"></i>
        Voltar
        </button>
    </div>
    <div >
      <p> VIDEO </p>
    </div>
    <div class="button">
      <button></button>
    </div>
  </div>
</template>

<style scoped>
.corpo{
  position: fixed;
  background-color: transparent;
  width: 35vw;
  height: 50vh;
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 15px;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 10px;
  z-index: 9;
  margin-left:50vh
}
.btn-voltar {
  background-color: white;
  border: none;
  cursor: pointer;
  color: #777575;
  font-size: 15px;
  margin-left: 27vw;
}

</style>
