<script>
export default {
  name: 'qrCode',
  props: {
    status_conexao: String,
    state: String,
    qrCode: String,
  },
  data() {
    return {
      showCountdown: false,
      maxTime: 60
    }
  },
  methods: {
    // inicia uma conexão
    enviarConexao() {
      this.time();
      this.$emit('enviarConexao')
    },
    // remove uma conexão
    removerConexao() {
      this.$emit('removerConexao')
    },
    // quando a conexão passa do timeout
    failedConnection(){
      this.maxTime = 60;
      this.$emit('failedConnection')
    },
    toRemoveShowCountdown(){
      this.showCountdown = false
    },
    mudancaStatus(){
      this.showCountdown = false;
    },
    time(){
      this.showCountdown = true;
      const interval = setInterval(() => {
        if (this.maxTime > 0) {
          this.maxTime--;
        } else {
          clearInterval(interval); 
          this.showCountdown = false;
          this.failedConnection();
        }
      }, 1000);
    }
  },
}
</script>

<template>
  <main class="item" id="elemento_imagem">
    <label class="titulo_form">Conectar ao WhatsApp    
      
  
  </label>
    <div class="espaco_imagem" id="carregamento">
      <div
        class="loading"
        id="carregamento"
        v-if="status_conexao === 'carregando'"
      >
        <span class="loader"></span>
      </div>

      <div
        v-else-if="status_conexao === 'carregado'"
        class="QrCode_conteiner"
        style="position: relative"
      >
        <img class="Qrcode" :src="qrCode" />
      </div>

      <div v-else-if="status_conexao === 'conectado'" class="retorno">
        <span><i class="fa-solid fa-check" style="color: #2bb542"></i></span>
      </div>

      <div v-else-if="status_conexao === 'error'" class="retorno">
        <i class="fa-solid fa-x" style="color: #ff3c3c"></i>
      </div>

      <div class="noLoad" id="carregamento" v-else>
        <img
          class="tempQrcode"
          src="../../../DataSendImagens/QrCode_DataSend.png"
          alt=""
        />
      </div>
    </div>
    <div class="time">
      <p class="style-score" v-if="showCountdown" >QR code: {{ maxTime }}</p>
    </div>
    <span
      v-if="status_conexao === 'conectado'"
      class="Conectar"
      style="background-color: #ff3c3c"
      @click="removerConexao"
    >
      Desconectar</span
    >
    <span v-else-if="status_conexao === 'carregando'" class="state">{{
      state
    }}</span>
    <div v-else class="Conectar" @click.prevent="enviarConexao">
      Conectar
    </div>
  </main>
</template>

<style scoped>
#animada {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#carregamento {
  display: flex;
  font-size: 50px;
  justify-content: center;
  align-items: center;
}

.QrCode_conteiner {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.Qrcode {
  width: 98%;
  height: 98%;
}

.tempQrcode {
  border-radius: 20px;
  width: 80%;
  aspect-ratio: 1/1;
}

.Conectar {
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  background-color: #2bb542;
  border: none;
  color: white;
  cursor: pointer;
  box-shadow: 1px 1px 2px 2px rgb(221, 221, 221);
}
.Conectar:hover{
  background-color: #2bb542d7;
}

.loader {
  color: #2bb542;
  font-size: 60px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  animation:
    mltShdSpin 1.7s infinite ease,
    round 1.7s infinite ease;
}
.time{
  display: flex;
  width: 100%;
  height: 2em;
  align-items: center;
  margin-left: 8vw;
  font-size: 1.2rem;
}

@keyframes mltShdSpin {
  0% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em,
      -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
