<script>
export default {
  name: 'popupAbout',

  data() {
    return {
      DataPriority:["", ""]
    }
  },

  props: {
    typeText: {
      required: true,
    },
  },

  methods: {
    emitHidePopup() {
      this.$emit('HandleHidePopup')
    },
  },

  computed: {
    text() {},
  },
}
</script>

<template>
  <div class="content" >
    <div  class="backgroud"></div>
    <main class="popup">
      <section class="title">
        <h1>O que é o DataSend</h1>
        <span @click="emitHidePopup"><i class="fa-solid fa-xmark"></i></span>
      </section>

      <section class="text">
        <p>O DataSend é um produto desenvolvido pela empresa DataPriority, projetado para proporcionar praticidade aos usuários do Qlik. Com uma interface simples e intuitiva, o DataSend permite que os usuários recebam informações cruciais para seus negócios através do WhatsApp.</p>
        <p>Hospedado nos robustos servidores da AWS (Amazon Web Services), o DataSend se conecta diretamente ao WhatsApp via API VENON. Essa integração possibilita a criação de um bot personalizado, adaptado às necessidades do usuário, permitindo o acesso ao Qlik por meio do WhatsApp.</p>
      </section>
    </main>
  </div>
</template>

<style scoped>

*{
  font-family: 'Open Sans', sans-serif;
}
.backgroud {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 0.7;
  z-index: 3;
}

.content{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  
  z-index: 4;
}

.popup {
  font-family:  Arial, Helvetica, sans-serif;;
  padding: 40px;
  z-index: 5;
  background: white;
  width: 40vw;
  aspect-ratio: 1/1;
  opacity: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

h1 {
  font-size: 2rem;
  color: #2bb542;
}


.title{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.text{
  text-align: justify;
  height: 90%;
  line-height: 1.5;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap:20px;
  text-indent: 5px;
}
span{
  cursor: pointer;
}

</style>
