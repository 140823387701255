<script>
export default {
  name: 'popupAboutFun',

  props: {
    typeText: {
      required: true,
    },
  },

  methods: {
    emitHidePopup() {
      this.$emit('HandleHidePopup')
    },
  },

  computed: {
    text() {},
  },
}
</script>

<template>
  <div class="content" >
    <div  class="backgroud"></div>
    <main class="popup">
      <section class="title">
        <h1>Funcionalidades Atuais</h1>
        <span @click="emitHidePopup"><i class="fa-solid fa-xmark"></i></span>
      </section>

      <section class="text">
        <table >
        <thead cellspacing="0">
            <tr>
              <th>Usuario</th>
              <th>Desenvolvedor</th>
            </tr>
        </thead>
        <tbody cellspacing="0">
            <th>
                <tr>Cadastro da empresa</tr>
                <tr>Cadastro de funcionários</tr>
                <tr>Conexão com WhatsApp</tr>
                <tr>Adicionar Funcionário</tr>
                <tr>Remover Funcionário</tr>
                <tr>Restringir Horário de expediente do Funcionário</tr>
                <tr>Filtrar acesso do funcionário a tabelas</tr>
            </th>
            <th>
              <tr>Gerenciamento de empresas</tr>
              <tr>Gerenciamento de usuários</tr>
              <tr>Gerenciamento de funcionários</tr>
            </th>
        </tbody>
    </table>
      </section>
    </main>
  </div>
</template>

<style scoped>

*{
  font-family: 'Open Sans', sans-serif;
}
.backgroud {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 0.7;
  z-index: 3;
}

.content{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  
  z-index: 4;
}

.popup {
  font-family:  Arial, Helvetica, sans-serif;;
  padding: 40px;
  z-index: 5;
  background: white;
  width: 80vw;
  height: 60vh;
  opacity: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

h1 {
  font-size: 2rem;
  color: #2bb542;
}


.title{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.text{
  text-align: justify;
  height: 90%;
  line-height: 1.5;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap:20px;
  text-indent: 5px;
}
span{
  cursor: pointer;
}

tr{
  border: 1px solid grey;
  font-weight: 400;
}

</style>
