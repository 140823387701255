<script>
import axios from "axios";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {},

  data() {
    return {
      email: "",
      senha: "",
      loading: false,
      error: false,
      showPassword: false,
      mostrarSenha: false,
    };
  },

  validations() {
    return {
      email: { required },
      senha: { required },
    };
  },

  created() {
    if (localStorage.length > 0) {
      localStorage.clear();
      location.reload();
    }
  },
  methods: {
    emitApp() {
      this.$emit("atualizarPagina");
    },

    retirarError() {
      this.error = false;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    mudarEstadoSenha() {
      this.mostrarSenha = !this.mostrarSenha;
    },
    forgotPassword() {
      this.$router.push({ name: "RecuperarSenha" });
    },
    async sendCredentialsForLogin() {
      try {
        this.loading = true;
        const data = {
          email: this.email,
          senha: this.senha,
        };
        this.loading = true;

        const url = process.env.VUE_APP_API_URL;

        const response = await this.$axios.post(url + "/login", data);

        if (response.data) {
          localStorage.setItem("User", JSON.stringify(response.data));

          if (JSON.stringify(response.data.User).includes("Data Priority")) {
            this.$store.commit("autorizar");
            this.emitApp();
          }
          this.loading = false;
          this.$router.push("/usuario");
        } else {
          this.loading = false;
        }
      } catch (error) {
        console.log(error);

        this.loading = false;
        this.error = true;
      }
    },
    async validar_envio() {
      this.v$.$validate();
      if (this.v$.$error) {
        alert("Preencha Campos corretamente");
      } else {
        await this.sendCredentialsForLogin();
      }
    },
  },
};
</script>

<template>
  <main @keyup.enter="validar_envio()">
    <div class="img-login"></div>
    <div class="form">
      <div class="img">
        <img src="../../DataSendImagens/Logo.svg" alt="" />
      </div>
      <form @keyup="retirarError">
        <p
          v-if="error"
          class="input_error"
          style="text-align: center; font-size: 1.3rem"
        >
          Login e/ou senha incorretos.
        </p>
        <div>
          <label for="email">Login da empresa</label>
          <input
            :class="[{ error: v$.email.$error }]"
            type="name"
            id="email"
            placeholder="Digite seu email"
            v-model="v$.email.$model"
          />
          <p class="input_error" v-if="v$.email.$error">Campo obrigatório</p>
        </div>
        <div>
          <div style="display: flex">
            <label for="senha">Senha</label>
          </div>
          <div class="verSenha">
            <input
              :class="[{ error: v$.senha.$error }]"
              :type="mostrarSenha ? 'text' : 'password'"
              class="input_padrao"
              id="senha"
              placeholder="********"
              autocomplete="off"
              v-model="v$.senha.$model"
            />
            <button
              class="button-senha"
              @click="mudarEstadoSenha"
              type="button"
            >
              <i :class="mostrarSenha ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
            </button>
          </div>
          <div></div>
        </div>
        <span @click="forgotPassword" class="style-escSenha"
          >Esqueci minha senha
          <p class="traco"></p>
        </span>
        <button
          style="color: white"
          :class="[{ validado: !v$.$invalid }]"
          @click.prevent="validar_envio()"
        >
          Fazer login
        </button>
        <div :class="[{ validado: !v$.$invalid }]"></div>
        <!-- <a href="https://www.iubenda.com/privacy-policy/73727001" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Política de Privacidade ">Política de Privacidade</a>
      <a href="https://www.iubenda.com/privacy-policy/73727001/cookie-policy" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Política de Cookies ">Política de Cookies</a> -->
      </form>
      <div class="overlay" v-if="loading"></div>
    </div>
  </main>
</template>
<style scoped>
* {
  margin: 0;
}

.button-exibir {
  color: black;
}

button,
input {
  border: 1px solid #b3b3b3;
}
.traco {
  display: flex;
  width: 50%;
  border: 1px solid #b3b3b3;
}

.style-escSenha {
  font-size: 0.9rem;
  color: #b3b3b3;
  cursor: pointer;
}
.img-login {
  position: fixed;
  height: 100vh;
  width: 60vw;
  margin: 0;
  background: url(../../DataSendImagens/Group177.png) no-repeat;
  background-size: cover;
}
#senha {
  color: black;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.verSenha {
  display: flex;
  align-items: center;
  width: 100%;
}

.verSenha .button-senha {
  width: 20%;
  background: transparent;
  color: black;
  border: 1px solid #b3b3b3;
  height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1rem;
}
.form {
  position: absolute;
  right: 7.5%;
  bottom: 20%;
  height: 480px;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
  background-color: white;
}

.form input {
  padding: 15px;
  width: 100%;

  border-radius: 4px;
  font-size: 15px;
}

.form label {
  color: #b3b3b3;
}

.form button {
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.validado {
  background-color: #2bb542;
  color: white;
}

.error {
  border: 1px solid rgb(255, 108, 108);
}

p {
  color: rgb(255, 108, 108);
  font-size: 0.8rem;
}
</style>