// src/router/routes.js
import Login from '../views/Login.vue'
import Usuarios from '../views/Usuarios.vue'
import CadastroDeUsuarios from '../views/CadastroUsuario.vue'
import Configuracao from '../views/Configuracao.vue'
import navigationBar from '../views/navigationBar.vue'
import Empresa from '../views/Empresa.vue'
import aboutUs from '../views/about/about.vue'
import NotFound from '../views/NotFound.vue'
import numerosBloqueados from '../views/bloqueado/bloqueado.vue'
import telaExpediente from '../views/telaExpediente.vue'
import telaChatbot from '../views/telaChatbot.vue'
import configuracaoApp from '../views/configuracaoApp.vue'
import RecuperarSenha from '../views/RecuperarSenha.vue'

export const routes = [
  
  {
    path: '/',
    name: 'home',
    component: Login,
    meta: {
      public: true,
      
    },
  },
  {
    path: '/usuario',
    name: 'usuario',
    component: Usuarios,
    meta: {
      auth: true,
      
    },
  },
  {
    path: '/cadastrousuario',
    name: 'cadastrousuario',
    component: CadastroDeUsuarios,
    meta: {
      auth: true,
    },
  },
  {
    path: '/telaExpediente',
    name: 'telaExpediente',
    component: telaExpediente,
    meta: {
      auth: true,
    },
  },
  {
    path: '/RecuperarSenha',
    name: 'RecuperarSenha',
    component: RecuperarSenha,
    meta: {
      public: true,

    },
  },
  {
    path: '/configuracao',
    name: 'Configuracao',
    component: Configuracao,
    meta: {
      auth: true,

    },
  },
  {
    path: '/navigationBar',
    name: 'navigationBar',
    component: navigationBar,
    meta: {
      public: true,
      admin: true,
    },
  },
  {
    path: '/empresa',
    name: 'Empresa',
    component: Empresa,
    meta: {
      auth: true,
      admin: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: aboutUs,
    meta: {
      public: true,
    },
  },
  {
    path: '/bloqueio',
    name: 'bloqueio',
    component: numerosBloqueados,
    meta: {
      auth: true,
    },
  },
  {
    path: '/telaChatbot',
    name: 'telaChatbot',
    component: telaChatbot,
    meta: {
      auth: true,
    },
  },
  {
    path: '/configuracaoApp',
    name: 'configuracaoApp',
    component: configuracaoApp,
    meta: {
      auth: true,
      admin: true,
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      public: true,
    },
  },

]