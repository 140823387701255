<script>
export default {
  name: 'popupAboutDS',

  data() {
    return {
      DataPriority:["", ""]
    }
  },

  props: {
    typeText: {
      required: true,
    },
  },

  methods: {
    emitHidePopup() {
      this.$emit('HandleHidePopup')
    },
  },

  computed: {
    text() {},
  },
}
</script>

<template>
  <div >
    <div  class="backgroud"></div>
    <main class="popup">
      <section class="title">
        <h1>Como Funciona o DataSend</h1>
        <span @click="emitHidePopup"><i class="fa-solid fa-xmark"></i></span>
      </section>

      <section class="text">
        <h2>Para entender o funcionamento do DataSend, é fundamental compreender os seguintes conceitos:</h2>
        <ul>
          <li><strong>Administrador:</strong> Responsável pela empresa que gerencia e mantém o DataSend, o Administrador tem acesso a todas as funcionalidades do sistema.</li>
        <li><strong>Usuário:</strong> Aquele que adquire ou é designado para utilizar o DataSend, o Usuário tem acesso apenas às funcionalidades destinadas aos usuários. Ele pode gerenciar funcionários, horários de envio de mensagens e adicionar/remover usuários.</li>
        <li><strong>Funcionário:</strong> Não tem acesso direto ao DataSend; sua função é receber mensagens enviadas pelo DataSend.</li>
    </ul>
        <h2>O bot do DataSend permite que os funcionários acessem as informações do Qlik de duas maneiras:</h2>
        <ul>
        <li><strong>Requisição Instantânea:</strong> Os funcionários podem enviar uma mensagem ao bot, solicitando as tabelas de dados desejadas, e o bot responderá prontamente, fornecendo as informações solicitadas.</li>
        <li><strong>Disparo Programado:</strong> Além disso, os funcionários têm a opção de agendar horários específicos para receber automaticamente as tabelas de dados em intervalos definidos, garantindo que as informações estejam disponíveis quando necessário.</li>
    </ul>
      </section>
    </main>
  </div>
</template>

<style scoped>

*{
  font-family: 'Open Sans', sans-serif;
}
.backgroud {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 0.7;
  z-index: 3;
}

.popup {
  font-family:  Arial, Helvetica, sans-serif;;
  padding: 40px;
  position: fixed;
  z-index: 4;
  top: 2%;
  left: 20%;
  background: white;
  width: 60dvw;
  height: 80dvh;
  opacity: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

h1 {
  font-size: 2rem;
  color: #2bb542;
}


.title{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.text{
  text-align: justify;
  height: 90%;
  line-height: 1.5;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap:20px;
  text-indent: 5px;
}

h2{
  font-size: 1.5rem;
}
</style>
