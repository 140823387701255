import axios from 'axios'
const url = process.env.VUE_APP_API_URL

async function getUsuarioCadastrado(_routerQueryId) {
  return axios
    .get(url + '/usuario/cadastrado', {
      params: {
        id: _routerQueryId,
      },
    })
    .then((response) => {
      const usuarioJaCadastrado = {
        nome: response.data.nome,
        email: response.data.email,
        numeroWhatsapp: response.data.contato,
        assistente: response.data.status,
        filtro: response.data.filtro,
        shooting: response.data.shooting,
        expediente: response.data.expediente
      }
      return usuarioJaCadastrado
    }).catch((error) => {
      console.error(error)
    })
}

async function postCadastroBanco(user) {
  if (user.idusuario) {
    return axios.put(url + '/usuario/cadastrado', user).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
  } else {
    return axios.post(url + '/usuario', user).then((response) => {
      return response
    }).catch((error) => {
      return error
    })

  }
}

async function getSheetByid(_routerQueryId) {
  const jsonData = { id: _routerQueryId }
  const panel = await axios.get(url + '/empresa/qlik', {
    params: jsonData,
  })

  return panel
}

async function getObjectByid(_routerQueryId, painelId, pastaId) {
  const objJson = {
    id: _routerQueryId,
    painel: painelId,
    sheet: pastaId,
  }

  const reqObject = await axios.get(url + '/empresa/qlik', {
    params: objJson,
  })
  const objetos = await reqObject.data
  return objetos
}

async function getDisparo(qlik) {
  const response = await axios.get(url + '/shooting', {
    params: {
      qlik: qlik,
    },
  })

  return response.data
}


async function postDisparo(objetoParaEnviarBanco) {
  await axios.post(url + '/shooting', objetoParaEnviarBanco
  ).then((response) => {
    return response.data
  })
}

export { getDisparo, postDisparo }

export { getUsuarioCadastrado, postCadastroBanco, getSheetByid, getObjectByid }
