<template>
  <main>
    <div class="img-login"></div>
    <div class="form">
      <div class="container">
        <div class="progress-bar">
          <div v-for="(step, index) in steps" :key="index" class="step">
            <p>{{ step.label }}</p>
            <div class="bullet">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="check fas fa-check" v-if="currentStep > index"></div>
          </div>
        </div>
      </div>

      <div class="style-step" v-if="step === 1">
        <p class="traco"></p>
        <div>
          <div style="width: 80%; display: flex; align-items: center">
            <button class="button-anterior" @click="backScreen">
              <i id="icon" class="fa-solid fa-chevron-left"></i>
            </button>
            <h1 class="title">Identificação</h1>
          </div>
        </div>
        <label for="login">Login</label>
        <input
          class="padrao"
          type="text"
          id="login"
          v-model="form.login"
          placeholder="Digite seu login"
        />
        <p v-if="loginError" class="error-message">{{ loginError }}</p>
      </div>
      <div class="style-step" v-if="step === 2">
        <p class="traco"></p>
        <div>
          <div style="width: 80%; display: flex; align-items: center">
            <button v-if="step > 1" class="button-anterior" @click="prevStep">
              <i id="icon" class="fa-solid fa-chevron-left"></i>
            </button>
            <h1 class="title">Autorização</h1>
          </div>
        </div>
        <div class="txt">
          <div style="width: 100%">
            <span class="style-txt"
              >Digite o código de 4 dígitos que enviamos por
              <b class="wpp-style">WhatsApp</b> para o administrador {{
            }}</span>
          </div>
          <form>
            <div class="input-field">
              <input
                v-for="(value, index) in otp"
                :key="index"
                :maxlength="1"
                type="text"
                :value="value"
                :ref="'input' + index"
                @input="handleInput(index, $event)"
                @keydown="handleKeydown(index, $event)"
              />
            </div>
            <p v-if="codeError" class="error-message">{{ codeError }}</p>
          </form>
          <div>
            <button @click="resendCode" class="button-Reenvio">
              Não recebeu? Reenviar o código
            </button>
            <p class="tracoo"></p>
          </div>
        </div>
      </div>
      <div class="style-step" v-if="step === 3">
        <p class="traco"></p>
        <div style="width: 90%; display: flex; align-items: center">
          <div v-if="!successMessage">
            <button v-if="step > 1" class="button-anterior" @click="prevStep">
              <i id="icon" class="fa-solid fa-chevron-left"></i>
            </button>
          </div>
          <div style="width: 100%">
            <div v-if="!successMessage" class="title-senha">
              Criar nova senha
            </div>
          </div>
        </div>

        <div v-if="!loading && !successMessage">
          <div class="style-space">
            <label for="login">Login da empresa</label>
            <input
              style="background-color: rgb(217, 217, 217); border: none"
              class="padrao"
              type="text"
              id="loginn"
              :readonly="true"
              v-model="form.login"
              :disabled="NameEmpresa"
            />
          </div>
          <div class="style-space">
            <label>Nova senha</label>
            <input
              class="padrao"
              type="password"
              v-model="newPassword"
              placeholder="Digite a nova senha"
            />
          </div>
          <div class="style-space">
            <label>Confirmar senha</label>
            <input
              class="padrao"
              type="password"
              v-model="confirmPassword"
              placeholder="Confirme a nova senha"
            />
            <p v-if="senhaError" class="error-message">{{ senhaError }}</p>
          </div>
        </div>
        <div v-if="loading">
          <i id="loading-style" class="fa-solid fa-circle-notch fa-spin"></i>
        </div>
        <div v-if="successMessage">
          <i id="sucesso-style" class="fa-regular fa-circle-check"> </i>
          <p class="style-msg">Sua senha foi alterada com sucesso</p>
        </div>
      </div>
      <div style="width: 95%">
        <button class="button-proximo" @click="nextStep">
          {{ isSaved ? "Ir para Login" : step === 3 ? "Salvar" : "Próximo" }}
        </button>
      </div>
    </div>
  </main>
</template>



<script>
import { AlertDataSend } from "@/components/alert";

const url = process.env.VUE_APP_API_URL;
export default {
  data() {
    return {
      step: 1,
      loading: false,
      successMessage: false,
      loginError: "",
      codeError: "",
      code: "",
      idEmpresa: null,
      senhaError: "",
      form: {
        login: "",
        confirm: "",
        NameEmpresa: true,
      },
      otp: ["", "", "", ""],
      loading: false,
      steps: [
        { label: "Identificação" },
        { label: "Autorização" },
        { label: "Nova senha" },
      ],
    };
  },
  methods: {
    prevStep() {
      if (this.step > 1) {
        this.step--;
        this.updateProgress();
      }
    },
    async checkLogin() {
      try {
        const { data } = await this.$axios.post(url + "/login/validate", {
          login: this.form.login,
        });

        if (!data) {
          AlertDataSend("Erro, entre em contato com o suporte");
          return false;
        }
        this.idEmpresa = data.id_empresa;

        return true;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.errorMessage = error.message;
          return false;
        } else {
          AlertDataSend("Recuperação indisponível, Contate o suporte.");
          return 0;
        }
      }
    },
    async resendCode() {
      await this.$axios.post(url + "/login/validate", {
        login: this.form.login,
      });
    },
    async nextStep() {
      if (this.isSaved) {
        this.isSaved = false;
        this.backScreen();
      }
      if (this.step === 1) {
        const isLoginValid = await this.checkLogin();
        if (isLoginValid === false) {
          this.loginError = "Login incorreto";
          return;
        } else if (isLoginValid === 0) {
          return;
        } else {
          this.loginError = "";
        }
      }
      if (this.step === 2) {
        await this.verifyOtp();
        return;
      }
      if (this.step === 3) {
        if (this.newPassword === this.confirmPassword) {
          if (this.newPassword.length < 8) {
            this.senhaError = "A senha deve ter pelo menos 8 caracteres!";
            return;
          }
          this.loading = true;
          this.successMessage = false;
          try {
            const { data } = await this.$axios.patch(
              url + "/empresa/forgot/password",
              {
                idCompany: this.idEmpresa,
                newPassword: this.newPassword,
                confirmationNewPassword: this.confirmPassword,
              }
            );
            if (data) {
              this.successMessage = true;
              this.isSaved = true;
              this.senhaError = "";
            } else {
              this.senhaError = "Não foi possível alterar a senha";
            }
          } catch (error) {
            this.senhaError = "Ocorreu um erro. Tente novamente.";
          } finally {
            this.loading = false;
          }
        } else {
          this.senhaError = "As senhas não coincidem!";
          return;
        }
      }
      if (this.step < 3) {
        this.step++;
        this.updateProgress();
        // } else if (this.isSaved) {
        //   this.backScreen();
      }
    },
    backScreen() {
      this.$router.push("/");
    },
    async verifyOtp() {
      const code = this.otp.join("");
      //console.log("OTP:", code);
      try {
        const response = await this.$axios.post(
          url + "/changepassword/validate",
          {
            code,
            id_company: this.idEmpresa,
            withCredentials: true,
          }
        );

        if (response.data) {
          this.step++;
          this.updateProgress();
        } else {
          this.codeError = "Código OTP inválido.";
          return;
        }
      } catch (error) {
        //console.error("Erro ao validar o OTP:", error);
        this.codeError =
          "Erro ao validar código de autorização. Tente novamente.";
        return;
      }
    },

    handleInput(index, event) {
      const value = event.target.value;
      this.otp.splice(index, 1, value);
      if (value && index < this.otp.length - 1) {
        this.$refs[`input${index + 1}`][0].focus();
      }
    },

    handleKeydown(index, event) {
      if (event.key === "Backspace") {
        if (this.otp[index] === "") {
          if (index > 0) {
            this.otp[index - 1] = "";
            this.$refs[`input${index - 1}`][0].focus();
          }
        } else {
          this.otp[index] = "";
        }
      }
    },
    updateProgress() {
      this.$nextTick(() => {
        const bullets = document.querySelectorAll(
          ".progress-bar .step .bullet"
        );
        const progressChecks = document.querySelectorAll(
          ".progress-bar .step .check"
        );
        const progressTexts = document.querySelectorAll(
          ".progress-bar .step p"
        );

        bullets.forEach((bullet, index) => {
          bullet.classList.toggle("active", index < this.step - 1);
        });

        progressChecks.forEach((check, index) => {
          check.classList.toggle("active", index < this.step - 1);
        });

        progressTexts.forEach((text, index) => {
          text.classList.toggle("active", index < this.step - 1);
        });
      });
    },
  },
  mounted() {
    this.updateProgress();
  },
};
</script>

  <style scoped>
.wpp-style {
  font-size: 1.1rem;
}
#loginn {
  cursor: not-allowed;
}
.style-msg {
  display: flex;
  font-size: 1rem;
  justify-content: center;
  color: #2bb542;
}
#sucesso-style {
  color: #2bb542;
  padding: 6rem;
  bottom: 2rem;
  display: flex;
  font-size: 4rem;
  align-items: center;
  justify-content: center;
}
#loading-style {
  color: #2bb542;
  padding: 6rem;
  bottom: 2rem;
  display: flex;
  font-size: 4rem;
  align-items: center;
  justify-content: center;
}
.style-step {
  padding: 3px;
  height: 100%;
  width: 100%;
}
::selection {
  color: #fff;
  background: #d43f8d;
}
.container {
  width: 350px;
  height: 7vw;
  background: transparent;
  text-align: center;
  border-radius: 5px;
}
.container header {
  font-size: 35px;
  font-weight: 600;
  margin: 0 0 30px 0;
}

.container .progress-bar {
  display: flex;
  margin: 40px 0;
  user-select: none;
}
.container .progress-bar .step {
  text-align: center;
  width: 100%;
  position: relative;
}
.container .progress-bar .step p {
  font-size: 1rem;
  color: #b3b3b3;
}
.progress-bar .step .bullet {
  height: 25px;
  width: 25px;
  border: 2px solid #b3b3b3;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  transition: 0.2s;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
}
.progress-bar .step .bullet.active {
  border-color: #2bb542;
  background: #2bb542;
}
.progress-bar .step .bullet span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.progress-bar .step .bullet.active span {
  color: white;
}
span {
  color: #b3b3b3;
}
.progress-bar .step .bullet:before,
.progress-bar .step .bullet:after {
  position: absolute;
  content: "";
  bottom: 11px;
  right: -93px;
  height: 2px;
  width: 93px;
  background: #b3b3b3;
}
.progress-bar .step .bullet.active:after {
  background: #2bb542;
  transform: scaleX(0);
  transform-origin: left;
  animation: animate 0.3s linear forwards;
}
@keyframes animate {
  100% {
    transform: scaleX(1);
  }
}
.progress-bar .step:last-child .bullet:before,
.progress-bar .step:last-child .bullet:after {
  display: none;
}
.progress-bar .step p.active {
  color: #2bb542;
  transition: 0.2s linear;
}
.progress-bar .step .check {
  position: absolute;
  left: 50%;
  top: 70%;
  font-size: 15px;
  transform: translate(-50%, -50%);
  display: none;
}
.progress-bar .step .check p.active {
  display: block;
  color: black;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.button-Reenvio {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #b3b3b3;
  font-size: 0.8rem;
}
.button-Reenvio:hover {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #2bb542;
  font-size: 0.8rem;
}
.style-space {
  padding: 4px;
}
.traco {
  display: flex;
  width: 100%;
  border: 1px solid #b3b3b3;
}
.tracoo {
  display: flex;
  width: 100%;
  border: 1px solid #b3b3b3;
}
.tracoo:hover {
  display: flex;
  width: 100%;
  border: 1px solid #2bb542;
}
.style-txt {
  color: #b3b3b3;
  flex-wrap: wrap;
  width: 13vw;
}

body {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4070f4;
}
:where(.txt, form, .input-field, header) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.txt {
  background: transparent;
  padding: 15px 65px;
  border-radius: 12px;
  row-gap: 20px;
}
.txt header {
  height: 65px;
  width: 65px;
  color: #fff;
  font-size: 2.5rem;
  border-radius: 50%;
}
.txt h4 {
  font-size: 1.25rem;
  color: #333;
  font-weight: 500;
}
form .input-field {
  flex-direction: row;
  column-gap: 10px;
}
.input-field input {
  height: 45px;
  width: 42px;
  border-radius: 6px;
  outline: none;
  font-size: 1.125rem;
  text-align: center;
  border: 1px solid #ddd;
}
.input-field input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.input-field input::-webkit-inner-spin-button,
.input-field input::-webkit-outer-spin-button {
  display: none;
}
form button {
  margin-top: 25px;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  border: none;
  padding: 9px 0;
  cursor: pointer;
  border-radius: 6px;
  pointer-events: none;
  background: #6e93f7;
  transition: all 0.2s ease;
}
form button.active {
  background: #4070f4;
  pointer-events: auto;
}
form button:hover {
  background: #0e4bf1;
}
.button-anterior {
  border: none;
  background-color: transparent;
  color: black;
}
#icon {
  color: #b3b3b3;
  font-size: 0.9rem;
  /* margin-left: -2.5vw; */
}
.button-proximo {
  border: none;
  width: 48%;
  background-color: #2bb542;
  color: white;
}
.title {
  display: flex;
  color: #b3b3b3;
}
.title-senha {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  color: #b3b3b3;
  font-size: 1.4rem;
  padding: 0px 55px;
}
* {
  margin: 0;
}

.button-exibir {
  color: black;
}

input {
  border: 1px solid #b3b3b3;
}

a {
  display: none;
}

.img-login {
  position: fixed;
  height: 100vh;
  width: 60vw;
  margin: 0;
  background: url(../../DataSendImagens/Group177.png) no-repeat;
  background-size: cover;
}

.form {
  position: absolute;
  right: 7.5%;
  bottom: 20%;
  height: 500px;
  width: 350px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
  background-color: white;
}
.padrao {
  padding: 15px;
}
.form input {
  width: 100%;

  border-radius: 4px;
  font-size: 15px;
}

.form label {
  color: #b3b3b3;
}

.form button {
  border-radius: 5px;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.validado {
  background-color: #2bb542;
  color: white;
}

.error {
  border: 1px solid rgb(255, 108, 108);
}

p {
  color: rgb(255, 108, 108);
  font-size: 0.8rem;
}
</style>