import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import store from '../store/index.js'


export default createStore( 
  {
  plugins:[createPersistedState()], 
  state: { devAut: false },
  mutations: {
    autorizar(state){
      state.devAut = true
    },
    desautorizar(state){
      state.devAut = false
      console.log(state.devAut)
    },
  },
  modules: {},
})
