<template>
  <div class="botoes" style="">
    <button class="Disparo" @click="emitEstado">Adicionar disparo</button>
  </div>
</template>

<script>
export default {
  name: 'BotoesTempo',

  methods: {
    emitEstado(){
        this.$emit("mudarEstadoCalendario")
    },
    },
}


</script>

<style scoped>
.botoes {
  /* Estilos para a div "botoes" */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}



.Disparo {
    width: 30%;
    font-size: 1rem;
    height: 30px;
    color: white;
    border: none;
    cursor: pointer;
    background: #3b9f4c;
    border-top-right-radius: 5px;
}

.Expediente {
  background: #298338;
}
</style>
