<script>
export default {
  name: 'popupSugestion',

  data() {
    return {
      DataPriority:["", ""]
    }
  },

  props: {
    typeText: {
      required: true,
    },
  },

  methods: {
    emitHidePopup() {
      this.$emit('HandleHidePopup')
    },
  },

  computed: {
    text() {},
  },
}
</script>

<template>
  <div class="content">
    <div  class="backgroud"></div>
    <main class="popup">
      <section class="title">
        <h1>Enviar sugestão</h1>
        <span @click="emitHidePopup"><i class="fa-solid fa-xmark"></i></span>
      </section>

      <section class="text">
        <aside class="form">
          <input type="text" placeholder="Seu nome (Opcional)">
          <div class="flex_input">
            <select name="" id="">
            <option value="">Tipo</option>
            <option value="">Bug</option>
            <option value="">Sugestão</option>
            <option value="">Elogio</option>
            <option value="">Critica</option>
          </select>
          <div>
            <label for="">Quando?</label>
            <input type="time" name="" id="">
          </div>
          </div>

        </aside>
        <aside class="textbox">
          <textarea name="" id="" cols="30" rows="10"></textarea>
          <div class="button_space">
          <button class="caixaTitulo_botao" @click='emitHidePopup()' >Enviar</button>
        </div>
        </aside>
      </section>
    </main>
  </div>
</template>

<style scoped>

*{
  font-family: 'Open Sans', sans-serif;
}

.form{
  height: 60%;
}

.flex_input{
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
}

.backgroud {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 0.7;
  z-index: 3;
}

.content{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  
  z-index: 4;
}

.popup {
  font-family:  Arial, Helvetica, sans-serif;;
  padding: 40px;
  z-index: 5;
  background: white;
  width: 60vw;
  height: 60vh;
  opacity: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

h1 {
  font-size: 2rem;
  color: #2bb542;
}

select{
  background: white;
  border: none;
  border-bottom: 1px solid grey;
  width: 50%;
  height: 20px;
  height: 40px;
  font-size: 1.2rem;
}

.title{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.text{
  text-align: justify;
  height: 90%;
  line-height: 1.5;
  overflow-y: scroll;
  display: flex;
  gap:20px;
  text-indent: 5px;
}

h2{
  font-size: 1.5rem;
}

aside{
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

.form{
  gap: 20px;
}

.textbox{
  justify-content: space-between;
}

.button_space{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

input{
  border: none;
  border-bottom: 1px solid grey;
  height: 40px;
  font-size: 1.2rem;
}

</style>
