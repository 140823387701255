<script>
export default{
    name: "popupDatasend",
  props: {
    removerFiltros:Boolean,
  },
  data() {
    return {
      mostrarPoup:true,
    };
  },
  created() {
    window.addEventListener("click", this.closeOnOutsideClick)
  },
  beforeDestroy() {
    window.removeEventListener("click", this.closeOnOutsideClick);
  },
  methods: {
    excluirFiltros(){
      this.$emit("excluirSelecionados");
    },
    // closeOnOutsideClick(event) {
    //   //console.log(this.$el.contains(event.target))
    //   const isClickInsideComponent = this.$el.contains(event.target);
    //   if (!isClickInsideComponent) {
    //       this.mostrarPoup = false
    //       // this.$emit("fecharComponente")
    //   }
    // },
    // closeOnOutsideClick(event) {
    //   if (event.key === "click") {
    //     this.$emit("fecharComponente");
    //   }
    // }
  }
}
</script>
<template>
      <div class="corpo" v-if="mostrarPoup">
        <div>
          <button v-if="removerFiltros" class="button-excluir" @click="excluirFiltros">
            Excluir
          </button>
        </div>
    </div>
</template>
<style scoped>
.corpo{
  position: fixed;
  background-color: white;
  width: 6vw;
  height: 4vh;
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding:3px;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 9;
  margin-right: 61vw;
  

}
.button-excluir{
  color: black;
  border: none;
  background-color: white;
  cursor: pointer;
  font-size: 1rem;
}
</style>