<script>
export default {
  props: { filtros: [] },
  data() {
    return {
      pagesDropdownOpen: false,
      dropdowns: {},
      filtrosFiltradosPainel: [],
      filtrosFiltradosPasta: [],
      filtrosFiltradosObjeto: [],
    }
  },
  watch: {
    filtros: {
      handler(novosFiltros) {
        this.filtrarFiltros();
      },

    }
  },
  created() {
    this.filtrarFiltros();
  },
  methods: {
    togglePagesDropdown() {
      this.pagesDropdownOpen = !this.pagesDropdownOpen
    },
    toggleDropdown(dropdown) {
      this.dropdowns[dropdown] = !this.dropdowns[dropdown]
    },
    handleDataChosen(data) {
      this.$emit('selectedOption', data)
    },
    filtrarFiltros() {
      this.filtrosFiltradosPainel = this.filtrarPorTipo('painel');
      this.filtrosFiltradosPasta = this.filtrarPorTipo('sheet');
      this.filtrosFiltradosObjeto = this.filtrarPorTipo('objeto');
    },
    filtrarPorTipo(tipo) {
      return this.filtros.filter((filtro, index) => {
        return this.filtros.findIndex(item => item[tipo] && item[tipo].title === filtro[tipo].title) === index;
      });
    }
  }
}
</script>
<template>
  <body>
    <div class="menu-bar">
      <ul>
        <li class="all-style" @click="togglePagesDropdown">
          <div class="button-onclik"> <i class="fa-solid fa-caret-down"></i></div>
          <div v-if="pagesDropdownOpen" class="dropdown-menu">
            <ul>
              <li>
                <a @click.stop="toggleDropdown('painel')"><i id="icons" class="fa-solid fa-magnifying-glass"></i>Painel <i id="icon-open" class="fas fa-caret-right"></i></a>
                <div v-if="dropdowns.painel" class="dropdown-menu-1">
                  <ul>
                    <li v-for="(filtro, index) in filtrosFiltradosPainel" :key="index"><p @click="handleDataChosen({ painel: filtro.painel.title })">{{ filtro.painel.title }}</p></li>
                  </ul>
                </div>
              </li>
              <li>
                <a @click.stop="toggleDropdown('pasta')"><i id="icons" class="fa-solid fa-magnifying-glass"></i>Pasta <i id="icon-openn" class="fas fa-caret-right"></i></a>
                <div v-if="dropdowns.pasta" class="dropdown-menu-1">
                  <ul>
                      <li v-for="(filtro, index) in filtrosFiltradosPasta" :key="index"><p @click="handleDataChosen({pasta: filtro.sheet.title})">{{ filtro.sheet.title }}</p></li>
                    </ul>
                </div>
              </li>
              <li>
                <a @click.stop="toggleDropdown('objeto')"> <i id="icons" class="fa-solid fa-magnifying-glass"></i>Objeto <i id="icon-open" class="fas fa-caret-right"></i></a>
                <div v-if="dropdowns.objeto" class="dropdown-menu-1">
                  <ul>
                    <li v-for="(filtro, index) in filtrosFiltradosObjeto" :key="index"><p @click="handleDataChosen({ objeto: filtro.objeto.title })">{{ filtro.objeto.title }}</p></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </body>
</template>
  
  <style scoped>
    :root {
    --color-primary: #0073ff;
    --color-whitee: #100f0f;
    --color-black: #141d28;
    --color-black-1: #212b38;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: sans-serif;
   
  }
    p{
      font-size: 17px;
    }
  #icons{
    color: #545556;
    font-size: 15px;
    margin-right: 5px;
  }
  #icon-open{
    font-size: 15px;
    margin-left: 45px;
  }
 #icon-openn{
  font-size: 15px;
    margin-left: 50px;
 }
  .button-onclik{
    color: black;
    font-size: 0.8rem;
  }
  .menu-bar {
    background-color: var(--color-black);
    height: 10px;
    width: 10px;
    display: flex;
    align-items: center;
    padding: 5 10%;
    position: relative;
    z-index: 10;
  }
  
  .menu-bar ul {
    list-style: none;
    display: flex;
  }
  .all-style{
    border: none;
  }
  ul li{
    border:  1px solid rgba(185, 184, 184, 0.747);
  }
  .menu-bar ul li {
    /* width: 120px; */
    /* text-align: center; */
    position: relative;
    color: black;
    cursor: pointer;
    background-color: white;
  }

  .menu-bar ul li a {
    font-size: 18px;
    color: var(--color-whitee);
    text-decoration: none;
    transition: all 0.3s;
  }
  
  .menu-bar ul li a:hover {
    color: var(--color-primary);
  }
  
  .fas {
    float: right;
    margin-left: 10px;
    padding-top: 3px;
  }
  
  /* dropdown menu style */
  .dropdown-menu {
    display: none;
  }
  
  .menu-bar ul li:hover .dropdown-menu {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    background-color: var(--color-black);
  }
  
  .menu-bar ul li:hover .dropdown-menu ul {
    display: block;
    margin: 2px;
  }
  
  .menu-bar ul li:hover .dropdown-menu ul li {
    width: 150px;
    padding: 5px;
  }
  
  .dropdown-menu-1 {
    display: none;
  }
  
  .dropdown-menu ul li:hover .dropdown-menu-1 {
    display: block;
    position: absolute;
    left: 147px;
    top: 0;
    background-color: var(--color-black);
    font-size: 16px;
  }
  </style>