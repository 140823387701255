function removerFiltro(id) {
  Obejetos.pop(id)
}

export function limpaMascara(stringSuja) {
  const caracteresParaRemover = ['(', ')', '-', '/', '.']
  let stringFiltrada = ''

  for (let i = 0; i < stringSuja.length; i++) {
    if (!caracteresParaRemover.includes(stringSuja[i])) {
      stringFiltrada += stringSuja[i]
    }
  }
  if (stringFiltrada.length >= 13) {
    stringFiltrada = stringFiltrada.slice(0, 4) + stringFiltrada.slice(5)
  }
  return stringFiltrada
}

function remover_filtro(e) {
  this.filtro.splice(e, 1)
}

function validate() {
  this.v$.$validate()
  if (this.v$.$error) {
    alert('Preencha os campos corretamente')
  }
}

function validar_cadastro(e) {
  this.v$.$validate()
  if (this.v$.$error) {
    alert('Preencha os campos corretamente')
  } else {
    this.enviarCadastro(e)
  }
}

function adcionar() {
  switch (true) {
    case this.sheet === '':
      this.sheet = { title: '*****' }
    case this.idObjeto === '':
      this.idObjeto = { title: '*****' }
      break
  }

  const dados = {
    painel: this.painel,
    sheet: this.sheet,
    objeto: this.idObjeto,
    campo: this.campo,
    conteudo: this.conteudo,
  }
  let verificacao = false
  console.log(dados)
  for (const item of this.filtro) {
    if (
      item.painel.title === dados.painel.title &&
      item.sheet.title === dados.sheet.title &&
      item.objeto.title === dados.objeto.title &&
      item.campo === dados.campo &&
      item.conteudo === dados.conteudo
    ) {
      verificacao = true
      break
    }
  }
  if (!verificacao) {
    this.filtro.unshift(dados)
    this.campo = ''
    this.conteudo = ''
  } else {
    this.msg = 'Filtro já cadastrado'
    setTimeout(() => (this.msg = ''), 2000)
  }
}

function enviarCadastro() {
  this.enviarCadastroParaBanco()
  this.trocarDePagina()
}

function trocarDePagina() {
  this.$router.push({ name: 'usuario' })
}

function limparPastaEObjeto() {
  if (this.sheets) {
    this.sheets = null
    this.sheet = ''
    this.objetos = ''
    this.idObjeto = ''
  }
}

function limparObjeto() {
  if (this.objetos) {
    this.objetos = ''
    this.idObjeto = ''
  }
}
